import { IDropdownOption } from "@fluentui/react";
import { format } from "date-fns";
import { getKustoResponse, getQueryParamsForLevel } from "api";
import {
    formatAppMetrics,
    formatCoreMetrics,
    formatScoreLines,
} from "api/commonApiHandler";
import { TenantTitleMetadata } from "components/CustomComponents/types";
import { getConfig, Workload } from "config/PlatformConfig";
import {
    KustoResponseType,
    MetricsResponseTableType,
    MetricsResponseType,
} from "pages/commonTypes";
import {
    CopilotCoreMetricsDeepLinksType,
    CopilotExecOverViewScoreType,
    CopilotExecOverviewFilterState,
    CopilotExecOverviewState,
    CopilotProductDrilldownCoreHealthMetricsType,
    CopilotProductDrilldownFilterState,
    CopilotProductDrilldownState,
} from "pages/Copilot/types";
import { formatWorkloadOverviewData } from "pages/ExecOverview/ApiHandler";
import { getTenantMetadataTemplate } from "pages/InProductDrillDown/InProductDrillDownHelper";
import { logException } from "utils/AppInsightsHelper";
import { copilotChartsPercentThreshold } from "utils/CopilotConstants";
import { TenantInsightsException, Severity } from "utils/Exceptions";
import { formatDateOptions } from "utils/Helpers";
import geojson from "utils/world.json";

export const fetchData = async (
    filters: CopilotExecOverviewFilterState,
    dateOptions: IDropdownOption[],
    scoreType: CopilotExecOverViewScoreType,
): Promise<CopilotExecOverviewState | string> => {
    try {
        const levelQueryParams = getQueryParamsForLevel(filters.level);

        const queryParams = {
            date: filters.date,
            platform: filters.platform,
            level: filters.level,
            cohort: filters.cohort,
            application: filters.application,
            mau: filters.mau,
            ...levelQueryParams,
        };

        const scoreQueryName = `copilot${scoreType}Score${
            filters.application === "WXP" ? "WXP" : ""
        }`;
        const tenantStatusQueryName = `copilot${scoreType}TenantStatus${
            filters.application === "WXP" ? "WXP" : ""
        }`;

        const [compositeScore, tenantStatus] = await Promise.all([
            getKustoResponse({
                queryName: scoreQueryName,
                platform: Workload.COPILOT_COMMERCIAL,
                queryParams,
            }),
            getKustoResponse({
                queryName: tenantStatusQueryName,
                platform: Workload.COPILOT_COMMERCIAL,
                queryParams,
            }),
        ]);

        try {
            const responseData = formatWorkloadOverviewData(
                compositeScore?.data,
                tenantStatus?.data,
                filters.date,
                dateOptions,
                filters.application,
                Workload.COPILOT_COMMERCIAL,
            );
            return {
                compositeScore: responseData.compositeScore,
                tenantStatus: responseData.tenantStatus,
                loading: false,
            };
        } catch (error) {
            logException(
                new TenantInsightsException(
                    Severity.SEV3,
                    "CopilotExecOverviewHealthDataProcessingFailed",
                ),
                {
                    message:
                        "Failure processing response data from Copilot ExecOverview queries",
                    responseCodes: {
                        compositeScore: compositeScore.status,
                        tenantStatus: tenantStatus.status,
                    },
                },
                error,
            );

            return error.message;
        }
    } catch (error) {
        logException(
            new TenantInsightsException(
                Severity.SEV3,
                "CopilotExecOverviewHealthDataFetchingFailed",
            ),
            {
                message: "Failure in fetching data for Copilot ExecOverview ",
            },
            error,
        );
        return error.message;
    }
};

export const getDateOptions = async () => {
    const [datesData] = await Promise.all([
        getKustoResponse({
            queryName: "copilotDateOptions",
            platform: Workload.COPILOT_COMMERCIAL,
        }),
    ]);
    return datesData?.data;
};

export const getHealthChartData = async (
    queryParams,
    scoreType: CopilotExecOverViewScoreType,
) => {
    let queryName = `copilot${scoreType}HealthDistribution`;
    const healthChart = await getKustoResponse({
        queryName: queryName,
        platform: Workload.COPILOT_COMMERCIAL,
        queryParams,
    });
    return healthChart;
};

export const getProductDrilldownData = async (
    filters: CopilotProductDrilldownFilterState,
    scoreType: CopilotExecOverViewScoreType,
) => {
    try {
        const levelQueryParams = getQueryParamsForLevel(filters.level);
        const queryParams = {
            level: filters.level,
            id: filters.id,
            ...levelQueryParams,
            date: filters.date,
            platform: filters.platform,
            application: filters.application,
        };

        const dates = await getDateOptions();
        const dateOptions = formatDateOptions(dates);

        const [
            productDrilldownMetadata,
            coreMetrics,
            cohortAvg,
            sparkLines,
            appMetrics,
        ] = await Promise.all([
            getKustoResponse({
                queryName: "copilotProductDrilldownMetadata",
                platform: Workload.COPILOT_COMMERCIAL,
                queryParams,
            }),
            getKustoResponse({
                queryName: `copilotCore${scoreType}Metrics`,
                platform: Workload.COPILOT_COMMERCIAL,
                queryParams,
            }),
            getKustoResponse({
                queryName: `copilot${scoreType}CohortAvg`,
                platform: Workload.COPILOT_COMMERCIAL,
                queryParams,
            }),
            getKustoResponse({
                queryName: `copilot${scoreType}SparkLines`,
                platform: Workload.COPILOT_COMMERCIAL,
                queryParams,
            }),
            getKustoResponse({
                queryName: `copilotAppMetrics`,
                platform: Workload.COPILOT_COMMERCIAL,
                queryParams,
            }),
        ]);

        const metadataTable: MetricsResponseTableType =
            productDrilldownMetadata?.data?.Tables[0];
        if (metadataTable.Rows.length === 0) {
            return getTenantMetadataTemplate();
        }

        // Schema - project Tpid, \${levelColumnName}, OrgName, IsS500, IsEPA, IsS2500, IsGoogleMove, IsGov, IsEO, IsCopilot, IsECAP`;
        const metadata: TenantTitleMetadata = {
            tpid: metadataTable.Rows[0][0],
            level: metadataTable.Rows[0][1],
            orgName: metadataTable.Rows[0][2],
            cohorts: {
                isS500: metadataTable.Rows[0][3],
                isEpa: metadataTable.Rows[0][4],
                isS2500: metadataTable.Rows[0][5],
                isGoogleMove: metadataTable.Rows[0][6],
                isGov: metadataTable.Rows[0][7],
                isEo: metadataTable.Rows[0][8],
                isCopilot: metadataTable.Rows[0][9],
                isEcap: metadataTable.Rows[0][10],
            },
        };

        const coreTilesMetrics: CopilotProductDrilldownCoreHealthMetricsType =
            formatCoreMetrics(
                coreMetrics?.data,
                cohortAvg?.data,
                Workload.COPILOT_COMMERCIAL,
                scoreType,
            );

        if (coreMetrics?.data?.Tables[0]?.Rows.length > 0) {
            coreTilesMetrics.pastStatus = coreMetrics?.data?.Tables[0]?.Rows[0][0];
            coreTilesMetrics.momScore = coreMetrics?.data?.Tables[0]?.Rows[0][1];
        }

        const sparkLineScores = formatScoreLines(
            sparkLines?.data,
            Workload.COPILOT_COMMERCIAL,
            scoreType,
        );

        const appMetricsData = formatAppMetrics(appMetrics?.data);

        return {
            metadata,
            dateOptions,
            coreHealth: coreTilesMetrics,
            sparkLineScores,
            appMetrics: appMetricsData,
        };
    } catch (error) {
        logException(
            new TenantInsightsException(
                Severity.SEV3,
                "CopilotProductDrilldownDataFetchingFailed",
            ),
            {
                message: "Failure in fetching Data for Copilot Product Drilldown",
            },
            error,
        );
        return error.message;
    }
};

export const getAppsList = (scoreType: CopilotExecOverViewScoreType) => {
    const config = getConfig(Workload.COPILOT_COMMERCIAL);

    if (scoreType === "Readiness") {
        return ["All"];
    }
    return config.appsList;
};

export const getProductDrilldownTenantEnvironmentData = async (
    filters: Partial<CopilotProductDrilldownFilterState>,
) => {
    const levelQueryParams = getQueryParamsForLevel(filters.level);
    const queryParams = {
        level: filters.level,
        id: filters.id,
        ...levelQueryParams,
        date: filters.date,
        platform: filters.platform,
    };

    const [appCurrencyAndLicense, copilotEnvData] = await Promise.all([
        getKustoResponse({
            queryName: "copilotTenantAppCurrencyAndLicenseData",
            platform: Workload.COPILOT_COMMERCIAL,
            queryParams,
        }),
        getKustoResponse({
            queryName: "copilotTenantEnvironmentData",
            platform: Workload.COPILOT_COMMERCIAL,
            queryParams,
        }),
    ]);

    const formattedAppCurrencyAndLicenseData = formatAppCurrencyAndLicenseData(
        appCurrencyAndLicense?.data,
    );

    const formattedEnvData = formatEnvData(copilotEnvData?.data);
    return { ...formattedAppCurrencyAndLicenseData, ...formattedEnvData };
};

export const loadChartMetrics = async (
    metric: string,
    display: string,
    level: string,
    id: string,
    platform: string,
    setData: (value: React.SetStateAction<CopilotProductDrilldownState>) => void,
) => {
    setData((data) => {
        return {
            ...data,
            chartData: null,
        };
    });

    let queryParams = getQueryParamsForLevel(level);
    queryParams["id"] = id;
    queryParams["metric"] = metric;
    queryParams["platform"] = platform;
    queryParams = { ...queryParams };

    try {
        const tenantMetricsChart = await getKustoResponse({
            queryName: "copilotAppMetricsChartData",
            platform: Workload.COPILOT_COMMERCIAL,
            queryParams,
        });

        const tenantMetricsChartJson = tenantMetricsChart?.data;

        const data = tenantMetricsChartJson.Tables[0];
        const finalMetricChartsData = {
            headers: data.Columns.map((x: any) => x.ColumnName),
            rows: data.Rows,
            title: display,
        };

        setData((oldDataState) => {
            return {
                ...oldDataState,
                chartData: finalMetricChartsData,
            };
        });
    } catch (error) {
        logException(
            new TenantInsightsException(
                Severity.SEV2,
                "CopilotProductDrilldownChartMetricsFetchException",
            ),
            {
                message:
                    "Exception while fetching charts data in copilot product drilldown page",
                metric,
                display,
                level,
                id,
            },
            error,
        );
    }
};

export const formatAppCurrencyAndLicenseData = (data: MetricsResponseType) => {
    const tenantEnvironmentTable: MetricsResponseTableType = data.Tables[0];

    const tenantEnvironmentData = {};

    // Schema - Date, OmsTenantId, Tpid, Application, Metric, Name, Value, Total
    tenantEnvironmentTable?.Rows.forEach((element) => {
        const date = element[0];
        const metric = element[4];
        const name = element[5] === "" ? "NA" : element[5];
        const value = element[6];
        const total = element[7] ?? 1;
        const percent = Number(((value / total) * 100).toFixed(2));

        if (!(metric in tenantEnvironmentData)) tenantEnvironmentData[metric] = {};

        if (!(date in tenantEnvironmentData[metric]))
            tenantEnvironmentData[metric][date] = {};

        if (percent > copilotChartsPercentThreshold)
            tenantEnvironmentData[metric][date][name] = {
                value: value,
                percentage: percent,
            };
    });

    return {
        appCurrency: tenantEnvironmentData["AppCurrency"],
        licenseDistribution: tenantEnvironmentData["LicenseDistribution"],
        officeDiagnosticConsentLevel:
            tenantEnvironmentData["OfficeDiagnosticConsentLevel"],
    };
};

const geoDataMapping = new Map<string, any>();
const iSOtoNameMapping = new Map<string, string>();
geojson.features.forEach((entry) => {
    geoDataMapping.set(entry.properties.ISO_A2, entry);
    iSOtoNameMapping.set(entry.properties.ISO_A2, entry.properties.NAME_LONG);
});

export const formatEnvData = (data: MetricsResponseType) => {
    const tenantEnvironmentTable: MetricsResponseTableType = data.Tables[0];
    const geoDataMappingSelected = new Map<string, {}>();
    const tenantEnvironmentData = {};

    // Schema -  Date, Application, MetricName, UnitName, Value, Total
    tenantEnvironmentTable?.Rows.forEach((element) => {
        const date = element[0];
        const app = element[1];
        const metric = element[2];
        const unit = element[3];
        const value = element[4];
        const total = element[5] ?? 1;
        const percent = Number(((value / total) * 100).toFixed(2));

        if (!(metric in tenantEnvironmentData)) tenantEnvironmentData[metric] = {};

        switch (metric) {
            case "Location":
                if (!(app in tenantEnvironmentData[metric]))
                    tenantEnvironmentData[metric][app] = [];
                if (percent > copilotChartsPercentThreshold) {
                    const countryCode = unit;

                    tenantEnvironmentData[metric][app].push({
                        sessionCount: value,
                        countryCode,
                        title: iSOtoNameMapping.has(countryCode)
                            ? iSOtoNameMapping.get(countryCode)
                            : countryCode,
                        percent,
                    });
                    geoDataMappingSelected.set(
                        countryCode,
                        geoDataMapping.get(countryCode),
                    );
                }
                break;
            case "DAU":
            case "WAU":
                if (!(date in tenantEnvironmentData[metric]))
                    tenantEnvironmentData[metric][date] = {};

                tenantEnvironmentData[metric][date][app] = Number(value);
                break;
        }
    });

    return {
        geographicDistribution: {
            data: { Location: tenantEnvironmentData["Location"] },
            geoMapping: geoDataMappingSelected,
        },
        dau: tenantEnvironmentData["DAU"],
        wau: tenantEnvironmentData["WAU"],
    };
};

export const getTileLinks = (
    metric: string,
    filters: CopilotProductDrilldownFilterState,
): CopilotCoreMetricsDeepLinksType => {
    switch (metric) {
        case "ASHARate":
            // append T00:00:00 to force the date to be in UTC
            const endDate = format(
                new Date(filters.date.concat("T00:00:00")),
                "yyyy-MM-28",
            );
            return {
                toolTip: "Copilot ASHA",
                href:
                    `//asha-next.azurewebsites.net/?dashboardName=CopilotTenants&dataset=&datePreset=&app=${filters.application}&audienceGroup=All&platform=${filters.platform}&language=All&startDate=${filters.date}&endDate=${endDate}` +
                    (filters.level === "TenantId"
                        ? `&tenantId=${filters.id}&tpid=`
                        : `&tpid=${filters.id}&tenantId=`),
            };
    }
};

export function formatCopilotTenantsList(tenants: KustoResponseType<string>) {
    try {
        const tenantList = tenants.Tables[0];
        const rows: any[] = [];
        //  Tpid, OrgName_Translated, TenantId = OmsTenantId, TenantName_Translated, TpidStatus= "", OmsStatus = Color, IsS500, IsS2500, IsEPA, IsGoogleMove, IsECAP, IsSMB, IsSMC, IsGov, IsOther
        tenantList.Rows.forEach((element) => {
            rows.push({
                Tpid: element[0],
                OrganizationName: element[1],
                TenantId: element[2],
                TenantName: element[3],
                TpidStatus: element[4],
                OmsStatus: element[5],
                IsS500: element[6],
                IsS2500: element[7],
                IsEPA: element[8],
                IsGoogleMove: element[9],
                IsECAP: element[10],
                IsSMB: element[11],
                IsSMC: element[12],
                IsGov: element[13],
                IsOther: element[14],
                Show: true,
            });
        });

        return rows;
    } catch (error) {
        logException(
            new TenantInsightsException(
                Severity.SEV2,
                "CopilotProductDrilldownTenantListProcessingFailed",
            ),
            {
                message:
                    "Failure in processing tenant list response in copilot product drill down page",
                data: {
                    tenants,
                },
            },
            error,
        );
        throw error;
    }
}
