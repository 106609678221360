import { IChoiceGroupOption } from "@fluentui/react/lib/ChoiceGroup";
import { IDropdownOption } from "@fluentui/react/lib/Dropdown";
import { ColumnType } from "components/OverviewComponents/types";
import { CopilotExecOverViewScoreType } from "pages/Copilot/types";

export const S500_S2500CohortName = "S500_S2500";
export const copilotCohortOptions: IDropdownOption[] = [
    { key: "ECAP", text: "ECAP" },
    { key: S500_S2500CohortName, text: "S500 + S2500" },
    { key: "S500", text: "S500" },
    { key: "S2500", text: "S2500" },
    { key: "EPA", text: "EPA" },
    { key: "GoogleMove", text: "GoogleMove" },
    { key: "SMB", text: "SMB" },
    { key: "SMC", text: "SMC" },
    { key: "Gov", text: "US Gov" },
    { key: "Other", text: "Other" },
];
const cohortChoiceGroupMarginStyle = { field: { marginLeft: "5px" } };

export const copilotTenantListChoiceOptions: IChoiceGroupOption[] = [
    { key: "All", text: "All", styles: cohortChoiceGroupMarginStyle },
    { key: "IsECAP", text: "ECAP", styles: cohortChoiceGroupMarginStyle },
    { key: "IsS500", text: "S500", styles: cohortChoiceGroupMarginStyle },
    { key: "IsS2500", text: "S2500", styles: cohortChoiceGroupMarginStyle },
    { key: "IsEPA", text: "EPA", styles: cohortChoiceGroupMarginStyle },
    { key: "IsSMB", text: "SMB", styles: cohortChoiceGroupMarginStyle },
    { key: "IsSMC", text: "SMC", styles: cohortChoiceGroupMarginStyle },
    { key: "IsGov", text: "US Gov", styles: cohortChoiceGroupMarginStyle },
    { key: "IsOther", text: "Other", styles: cohortChoiceGroupMarginStyle },
    {
        key: "IsGoogleMove",
        text: "GoogleMove",
        styles: cohortChoiceGroupMarginStyle,
    },
];

export const copilotPlatformOptions: Record<
    CopilotExecOverViewScoreType,
    IDropdownOption[]
> = {
    Health: [
        { key: "Win32", text: "Win32" },
        { key: "Web", text: "Web" },
    ],
    Readiness: [{ key: "All", text: "All" }],
};

export const copilotMAUOptions: IDropdownOption[] = [
    { key: "0", text: ">0" },
    { key: "500", text: "500 (Recommended)" },
    { key: "1000", text: "1000" },
    { key: "2500", text: "2500" },
];

export const copilotScoreOptions: IChoiceGroupOption[] = [
    { key: "Readiness", text: "Readiness" },
    { key: "Health", text: "Health" },
];

export const copilotExecOverviewScoreTypeTabs: Record<
    CopilotExecOverViewScoreType,
    { icon: string; color: string }
> = {
    Readiness: {
        icon: "ReleaseGateCheck",
        color: "Blue",
    },
    Health: {
        icon: "Health",
        color: "Red",
    },
};

export const copilotExecOverViewTableNameMap: Record<
    CopilotExecOverViewScoreType,
    { [key: string]: { name: string; description?: string } }
> = {
    Health: {
        Health_Score: { name: "Score", description: "Health Score" },
        MAU_Score: { name: "MAU", description: "#Tried Users" },
        ASHA_Score: {
            name: "ASHA",
            description: "Aggregate Session HAppiness",
        },
        SATPct_Score: {
            name: "SAT",
            description: "Thumbs Down / 100K tries",
        },
        Performance_Score: {
            name: "ASHA Perf Veto",
            description: `Weighted avg of sessions in AshaVeto="'Response Delayed or Timed Out'`,
        },
        M1Retention_Score: {
            name: "M1 Retention",
            description: "1-Month Retention",
        },
        KeepRate_Score: {
            name: "Keep Rate",
            description: "Kept Sessions / Tried Sessions",
        },
        SeenRate_Score: {
            name: "Seen Rate",
            description: "Seen Sessions / Enabled Sessions",
        },
        TriedRate_Score: {
            name: "Tried Rate",
            description: "Tried Sessions / Seen Sessions",
        },
        Intensity_Score: {
            name: "Intensity",
            description: "#Tried counts / User",
        },
        MTE_Score: {
            name: "MTE",
            description: "Minimal Trustworthy Experience Survey",
        },
        FCFRP50_Score: {
            name: "FCFR P50",
            description: "First Chunk of First Response P50",
        },
        FCFRP95_Score: {
            name: "FCFR P95",
            description: "First Chunk First Response P95",
        },
        Currency_Score: {
            name: "Currency",
            description:
                "CC+MEC channels: #Devices on latest 2 app version releases / Total Devices for Copilot sessions on Win32 devices",
        },
        NPS_Score: {
            name: "NPS",
            description: "Copilot Net Promoter Score",
        },
    },
    Readiness: {
        Readiness_Score: { name: "Score", description: "Readiness Score" },
        OneDrivePct_Score: {
            name: "% OneDrive Business Users",
            description: "% OneDrive Business Users",
        },
        MIPEnabledPct_Score: {
            name: "% MIP Enabled Users",
            description: "% MIP Enabled Users",
        },
        EligibleLicenses_Score: {
            name: "Eligible M365 Licenses",
            description: "SUM(E3 + E5) Licenses",
        },
        CopilotLicenses_Score: {
            name: "Copilot Licenses",
            description: "Copilot Licenses purchased by Tenant",
        },
        CopilotEnabled_Score: {
            name: "Copilot Enabled",
            description: "Copilot Enabled Licenses by Tenant",
        },
        CopilotEnabledPct_Score: {
            name: "Copilot Enabled %",
            description: "Enabled Copilot Licenses / Available Copilot Licenses",
        },
        SPO_Labelling_Text: {
            name: "SPO Enabled",
            description: "If SPO Sensitivity Labelling is Enabled",
        },
        Collab_Protection_Text: {
            name: "Collab Enabled",
            description: "If SPO Collab Labelling is Enabled",
        },
        FbkAndLogsONPct_Score: {
            name: "In-Product Feedback+Logs",
            description:
                "% of copilot enabled users that have in-product feedback + logfiles enabled",
        },
        FbkSurveyONPct_Score: {
            name: "In-product Surveys",
            description:
                "% of copilot enabled users that have in-product surveys enabled",
        },
        ODDRate_Score: {
            name: "Office Data Diagnostic Rate",
            description: "Office Data Diagnostic Rate",
        },
        ConnectedExperienceRate_Score: {
            name: "Connected Experience",
            description:
                "% of copilot enabled users that have connected experiences policy enabled",
        },
    },
};

export const copilotDisabledAppList = [
    "M365 Chat",
    "Teams",
    "Outlook",
    "Whiteboard",
    "Loop",
    "Stream",
];

export const copilotHeaderColumnDetailsTable = {
    Score: [80, ColumnType.VALUE, "alignRightHeader"],
    Text: [50, ColumnType.TEXT, "alignRightHeader"],
    MoMChange: [40, ColumnType.MOM_CHANGE, "alignCenterHeader"],
    AppColor: [30, ColumnType.APPCOLOR, "alignCenterHeader"],
    Platform: [70, ColumnType.PLATFORM, "alignRightHeader"],
};

export const copilotChartsPercentThreshold = 0.5;

export const CopilotTenantTitleCohortTags: IDropdownOption[] = [
    ...copilotCohortOptions,
];
